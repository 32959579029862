
import Vue from "vue";
import { authMapGetters } from "@/store/modules/auth";
import { getCompanyLabel } from "../../../helpers/companies";
import { quoteMapState } from "@/store/modules/quote";

export default Vue.extend({
  name: "territory-lookup-select",
  props: {
    territories: {
      type: Array,
      required: false,
      default: () => []
    },
    value: {
      type: String,
      required: false,
      default: ""
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...authMapGetters(["getCurrentUser"]),
    ...quoteMapState(["editing"]),
    getCompanies(): any {
      const companies =
        this.getCurrentUser && this.getCurrentUser.companiesData
          ? this.getCurrentUser.companiesData
          : [];
      return companies;
    }
  },
  methods: {
    getTerritory(territory: any) {
      let companies: any = [];
      if (
        this.getCurrentUser &&
        (this.getCurrentUser.role === "admin" ||
          this.getCurrentUser.role === "underwriting" ||
          this.getCurrentUser.role === "claims" ||
          this.$isCurrentUserBilling ||
          this.$isCurrentUserDataEntry) &&
        !this.getCompanies.length
      ) {
        companies = territory.companyDetails;
      } else if (this.getCurrentUser && this.getCurrentUser.role === "ezlynx") {
        companies = this.editing.companyValidations.allowedCompanies;
      } else {
        companies = this.getCompanies;
      }
      const result = companies.map((company: any) => {
        const findCompany = territory.territoryInfo.companyTerritories.find(
          (territory: any) => territory.companyNumber == company.companyNumber
        );
        if (findCompany) {
          return `${getCompanyLabel(
            findCompany.companyNumber,
            company.companyName,
            "quote"
          )} - ${findCompany.terr}`;
        }
      });
      return result;
    },
    getSeacoastTerritories(territory: any) {
      let companies: any = [];
      if (
        this.getCurrentUser &&
        (this.getCurrentUser.role === "admin" ||
          this.getCurrentUser.role === "underwriting" ||
          this.getCurrentUser.role === "claims" ||
          this.$isCurrentUserBilling ||
          this.$isCurrentUserDataEntry) &&
        !this.getCompanies.length
      ) {
        companies = territory.companyDetails;
      } else if (
        this.getCurrentUser &&
        this.getCurrentUser.role === "ezlynx" &&
        this.editing.companyValidations?.allowedCompanies
      ) {
        companies = this.editing.companyValidations.allowedCompanies;
      } else {
        companies = this.getCompanies;
      }

      const companyTerritories = territory.territoryInfo.companyTerritories;

      const result = companies.map((company: any) => {
        const findCompany = companyTerritories.find(
          (territory: any) =>
            territory.companyNumber == company.companyNumber &&
            territory.isSeaCoast
        );
        if (findCompany) {
          return `${getCompanyLabel(
            findCompany.companyNumber,
            company.companyName,
            "quote"
          )} - ${findCompany.seaTerr}`;
        }
      });
      return result;
    }
  }
});
